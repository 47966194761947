
module.exports = (mn) => {
  var utils = mn.utils;
  var camelToKebabCase = utils.camelToKebabCase;
  var lowerFirst = utils.lowerFirst;

  mn.utils.flags([
    'backdropFilter',
  ], mn.propertiesStringify.prefixedAttrs);

  mn.synonyms({
    last: ':last-child',
  });

  mn({
    sticky: {
      style: {
        position: 'sticky',
      },
    },
  });

  mn('gap', (p) => {
    return {
      style: {
        gap: (p.num || '0') + (p.unit || 'px'),
      },
    };
  });

  mn('op', (p) => {
    return {
      style: {
        objectPosition: camelToKebabCase(lowerFirst(p.value || '')),
      },
    };
  });

  mn('ovb', (p) => {
    return {
      style: {
        overscrollBehavior: camelToKebabCase(lowerFirst(p.value || 'none')),
      },
    };
  });

};