import React from 'react';
import mnProvider from 'minimalist-notation';
import stylesRenderProvider from 'mn-utils/stylesRenderProvider';
import reactCreateElementPatch from 'minimalist-notation/browser/reactCreateElementPatch';
import mnPresetRuntimePrefixes from 'minimalist-notation/presets/runtimePrefixes';
import mnPresetStyles from 'minimalist-notation/presets/styles';
import mnPresetMedias from 'minimalist-notation/presets/medias';
import mnPresetSynonyms from 'minimalist-notation/presets/synonyms';
import mnPresetSelf from './styles.mn';

const mn = mnProvider({
  altColor: 'off',
  selectorPrefix: '',
  presets: [
    mnPresetRuntimePrefixes,
    mnPresetStyles,
    mnPresetMedias,
    mnPresetSynonyms,
    mnPresetSelf,
  ],
});

mn.styles$.on(stylesRenderProvider(document, 'mn.'));
mn.error$.on((error) => {
  console.error(error);
});

React.createElement = reactCreateElementPatch(React.createElement, {
  className: 'class',
}, mn);
